<template>
  <div class="main-content row">
    <div class="col-md-10 col-md-offset-1 p0">
      <form @submit.prevent="submit">
        <div class="info">
          <div class="row title">
            <div class="col-xs p0">General Information</div>
          </div>
          <div class="row">
            <div class="col-xs p0">
              <label for="advertiser_id"
                >Advertiser ID*:<input
                  v-model.number="form.advertiser_id"
                  type="text"
              /></label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs p0">
              <label for="name">
                Line Item Name*:
                <input v-model="form.name" type="text" name="name" />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs p0">
              <label for="clickthrough">
                Click-through URL*:
                <input
                  v-model="form.clickthrough_url"
                  type="text"
                  name="clickthrough"
                />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6 pl0">
              <label for="start_date"
                >Start Date*:
                <datepicker
                  name="start_date"
                  :value="form.start_date"
                  format="MM/dd/yyyy"
                  :disabled-dates="datepicker.start_date.disabled"
                  :highlighted="datepicker.highlighted"
                  @selected="didUpdateStartDate"
                ></datepicker>
              </label>
            </div>
            <div class="col-xs-6 pr0">
              <label for="end_date">
                End Date*:
                <datepicker
                  name="end_date"
                  :value="form.end_date"
                  format="MM/dd/yyyy"
                  :disabled-dates="datepicker.end_date.disabled"
                  @selected="didUpdateEndDate"
                ></datepicker>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6 pl0">
              <label for="">
                CPM*:
                <label for="cpm"
                  ><input v-model.number="form.cpm" type="text"
                /></label>
              </label>
            </div>
            <div class="col-xs-6 pr0">
              <label for="impressions">
                Impressions*:
                <label for="impressions"
                  ><input v-model.number="form.impressions" type="text"
                /></label>
              </label>
            </div>
          </div>
        </div>
        <div class="creative">
          <div class="row title">
            <div class="col-xs p0">Creatives*</div>
          </div>
          <div class="row">
            <div class="col-xs p0">
              <label for="upload">
                Upload Creative
                <input
                  v-model="creative"
                  type="radio"
                  name="upload"
                  value="upload"
                />
              </label>
            </div>
            <div class="col-xs p0">
              <label for="existing">
                Use Existing Creative
                <input
                  v-model="creative"
                  type="radio"
                  name="existing"
                  value="existing"
                />
              </label>
            </div>
          </div>
          <div v-if="creative === 'upload'" class="creative-upload center-xs">
            <div class="row">
              <div class="col-xs">
                <label for="upload">
                  First Party Creative
                  <input
                    v-model="upload_type"
                    type="radio"
                    name="upload_type"
                    value="upload"
                    :disabled="imageUploadDisabled"
                  />
                </label>
              </div>
              <div class="col-xs">
                <label for="existing">
                  3rd Party Creative
                  <input
                    v-model="upload_type"
                    type="radio"
                    name="upload_type"
                    value="input"
                    :disabled="thirdPartyUploadDisabled"
                  />
                </label>
              </div>
            </div>
            <div v-if="upload_type === 'upload'" class="row center-xs">
              <div class="col-xs-6">
                <file-upload
                  :chunk-size="10000"
                  @dr-file-upload-success="onFileUpload"
                  @dr-file-added="fileAdded"
                />
              </div>
              <div class="col-xs-4">
                <div class="icon">
                  <font-awesome-icon
                    v-show="imageUploading"
                    :icon="['fas', 'circle-notch']"
                    pulse
                    size="6x"
                  ></font-awesome-icon>
                </div>
                <img :src="imgSrc" class="image" />
              </div>
            </div>
            <div
              v-if="upload_type === 'input'"
              class="row center-xs input_upload"
            >
              <p class="col-xs-12">Paste HTML Below</p>
              <div class="col-xs-6">
                <div class="row">
                  <textarea
                    id="third_party"
                    v-model="form.creative.body"
                    name="third_party"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col-xs-6">
                    <input
                      v-model="form.creative.width"
                      type="text"
                      placeholder="Width"
                    />
                  </div>
                  <div class="col-xs-6">
                    <input
                      v-model="form.creative.height"
                      type="text"
                      placeholder="Height"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="creative === 'existing'" class="row">
            <div class="col-xs">
              <label for="apn_creative_id">
                AppNexus Creative ID:
                <input
                  id="apn_creative_id"
                  v-model.number="form.apn_creative_id"
                  type="text"
                  name="apn_creative_id"
              /></label>
            </div>
          </div>
        </div>
        <div class="cdn">
          <div class="row title">
            <div class="col-xs p0">CDN:</div>
          </div>
          <div class="row">
            <div class="col-xs p0">
              <label for="upload">
                Internal CDN
                <input
                  v-model="form.cdn"
                  type="radio"
                  name="cdn"
                  value="internal"
                />
              </label>
            </div>
            <div class="col-xs">
              <label for="existing">
                MNI CDN
                <input v-model="form.cdn" type="radio" name="cdn" value="mni" />
              </label>
            </div>
          </div>
        </div>
        <div class="confirm">
          <div class="row center-xs">
            <div class="col-xs-7">
              <v-button
                type="submit"
                :size="6"
                :event="submit"
                :disabled="!form_complete"
                >Submit
              </v-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
const FileUpload = () => import("@/components/elements/FileUpload/FileUpload");
import axios from "axios";

function form() {
  return {
    advertiser_id: null,
    name: null,
    start_date: new Date(),
    end_date: new Date(),
    cpm: null,
    impressions: null,
    apn_creative_id: null,
    creative: {},
    clickthrough_url: null,
    country: 233,
    cdn: "internal"
  };
}
export default {
  name: "Home",
  components: { FileUpload, Datepicker },
  data() {
    return {
      creative: "upload",
      form: form(),
      imgSrc: null,
      thirdPartySrc: null,
      showLoading: true,
      cdn: "internal",
      upload_type: "upload", //upload or input
      imageUploading: false,
      datepicker: {
        highlighted: {
          includeDisabled: false
        },
        start_date: {
          disabled: {
            to: (d => new Date(d.setDate(d.getDate() - 1)))(new Date())
          }
        },
        end_date: {
          disabled: {
            customPredictor: this.disabledEndDates
          }
        }
      }
    };
  },
  computed: {
    imageUploadDisabled() {
      return this.thirdPartySrc !== null;
    },
    thirdPartyUploadDisabled() {
      return this.imgSrc !== null;
    },
    creative_empty() {
      return (
        Object.keys(this.form.creative).length === 0 &&
        this.form.creative.constructor === Object
      );
    },
    form_complete() {
      const _form = this.form;
      return (
        _form.advertiser_id !== null &&
        (!this.creative_empty || this.apn_creative_id !== null) &&
        _form.name !== null &&
        _form.cpm > 0 &&
        _form.impressions > 0 &&
        _form.clickthrough_url !== null
      );
    }
  },
  methods: {
    fileAdded(file) {
      this.imageUploading = true;
    },
    onFileUpload(file, response) {
      const creative = response["creatives"][0];
      this.form.creative = { ...creative.metadata, url: creative.url };
      this.imageUploading = false;
      this.imgSrc = creative.url;
    },
    format_date(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      return date
        .toISOString()
        .split("T")
        .join(" ")
        .split(".")[0];
    },
    async submit() {
      this.$store.commit("SET_FORM_SUBMITTING", true);
      let body = {
        ...this.form,
        start_date: this.format_date(this.form.start_date),
        end_date: this.format_date(this.form.end_date),
        email: this.$store.state.authentication.user.email
      };
      try {
        await this.$store.dispatch("createTestPage", body).then(() => {
          this.$store.dispatch("createNotification", {
            messages: [
              "Test Page has been created!",
              "An email will be sent to your account with the details"
            ],
            title: "Success!"
          });
        });
      } catch (e) {
        const data = e.response.data;
        let messages = (data && data.messages) || [
          "Something went wrong!",
          "Please try again later"
        ];

        this.$store.commit("SET_NOTIFICATION_PROPS", {
          messages,
          title: "Error!",
          style: "alert"
        });
      } finally {
        this.$store.commit("SET_FORM_SUBMITTING", false);
      }
      this.form = form();
      this.imgSrc = null;
    },
    didUpdateStartDate(date) {
      if (date === null) {
        date = new Date();
      }

      this.form.start_date = date;
      if (this.form.end_date < date) {
        this.didUpdateEndDate(date);
      }
    },
    didUpdateEndDate(date) {
      if (date === null) {
        date = new Date();
      }
      this.form.end_date = date;
    },
    disabledEndDates(date) {
      //if date less than start date

      return date < this.form.start_date;
    }
  }
};
</script>
