import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
Vue.use(Vuex);
import axios from "axios";

const getDefaultNotification = () => ({
  messages: [],
  title: "",
  style: "success", //can be success, info, alert
  show: false
});
const getDefaultState = () => ({
  view: {
    notification: getDefaultNotification()
  },
  formSubmitting: false
});
export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    RESET_NOTIFICATION(state) {
      state.view.notification = getDefaultNotification();
    },
    SET_NOTIFICATION_PROP(state, { prop, value }) {
      state.view.notification[prop] = value;
    },
    SET_NOTIFICATION_PROPS(state, notification) {
      state.view.notification = notification;
    },
    SET_FORM_SUBMITTING(state, isSubmitting) {
      state.formSubmitting = isSubmitting;
    }
  },
  actions: {
    async createTestPage({ state }, body) {
      return axios({
        method: "post",
        url: `${process.env.VUE_APP_FORM_URL}/submit`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: body
      });
    },
    async createSegment({ state }, data) {
      let formData = new FormData();
      formData.append("code", data.code);
      formData.append("segment_name", data.segment_name);
      formData.append("file", data.file, data.file.name);
      formData.append("email", state.authentication.user.email);

      return axios({
        method: "post",
        url: `${process.env.VUE_APP_SEGMENT_UPLOAD_URL}/upload`,
        data: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        config: {
          headers: {
            "Content-Type": `multipart/form-data;
          boundary=${formData._boundary}`
          }
        }
      });
    },
    createNotification(
      { commit },
      {
        messages = [],
        title = "",
        style = "success",
        show = "true",
        duration = 10000
      }
    ) {
      commit("SET_NOTIFICATION_PROPS", {
        messages,
        title,
        style,
        show,
        duration
      });
    }
  },
  modules: { authentication }
});
